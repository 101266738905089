<template>
  <Dialog :common-dialog="dialog" :dialog-width="dialogWidth">
    <template v-slot:title
      >Visit Schedule ({{ preventiveScheduleOutput.length }})
    </template>
    <template v-slot:body>
      <v-container>
        <v-layout>
          <v-flex md6 class="custom-border-right mr-2">
            <h3 class="color-custom-blue font-weight-600 font-size-19">
              Schedule Suggested by The System
            </h3>
            <table width="100%">
              <tr
                v-for="(row, index) in prventiveRawScheduleOutput"
                :key="index"
                :class="{ 'visit-row-hover': row.isEdited }"
              >
                <td
                  class="font-size-14 font-weight-500"
                  align="center"
                  valign="middle"
                >
                  <v-btn icon v-if="index <= 1 && row.isPrimary">
                    <v-icon color="cyan" v-if="row.isPrimary"
                      >mdi-checkbox-marked</v-icon
                    >
                    <v-icon color="grey darken-1" v-else
                      >mdi-checkbox-blank-outline</v-icon
                    >
                  </v-btn>
                </td>
                <td
                  class="font-size-14 font-weight-500"
                  align="center"
                  valign="middle"
                >
                  {{ index + 1 }}.
                </td>
                <td class="font-size-14 font-weight-500">
                  {{ formatDate(row.startDate) }}
                </td>
                <td class="font-size-14 font-weight-500">
                  {{ row.daysOfWeek }}
                </td>
                <td class="font-size-14 font-weight-500">
                  {{ row.startTime || "-" }}
                </td>
                <td class="font-size-14 font-weight-500">
                  {{ row.endTime || "-" }}
                </td>
                <td>
                  <v-icon color="green lighten-1" v-if="row.status"
                    >mdi-check</v-icon
                  >
                  <v-icon color="red lighten-1" v-else>mdi-close</v-icon>
                </td>
              </tr>
            </table>
          </v-flex>
          <v-flex md6 class="ml-2">
            <h3 class="color-custom-blue font-weight-600 font-size-19">
              Changes Done by You
            </h3>
            <!--  <pre>{{ scheduleDiff }}</pre> -->
            <table width="100%">
              <template v-if="scheduleDiff.length">
                <tr
                  v-for="(row, index) in scheduleDiff"
                  :key="index"
                  :class="{ 'visit-row-hover': row.isEdited }"
                >
                  <td>
                    <v-btn icon v-if="index <= 1 && row.isPrimary">
                      <v-icon color="cyan" v-if="row.isPrimary"
                        >mdi-checkbox-marked</v-icon
                      >
                      <v-icon color="grey darken-1" v-else
                        >mdi-checkbox-blank-outline</v-icon
                      >
                    </v-btn>
                  </td>
                  <td
                    class="font-size-14 font-weight-500"
                    align="center"
                    valign="middle"
                  >
                    {{ index + 1 }}.
                  </td>
                  <td class="font-size-14 font-weight-500">
                    {{ formatDate(row.startDate) }}
                  </td>
                  <td class="font-size-14 font-weight-500">
                    {{ row.daysOfWeek }}
                  </td>
                  <td class="font-size-14 font-weight-500">
                    {{ row.startTime || "-" }}
                  </td>
                  <td class="font-size-14 font-weight-500">
                    {{ row.endTime || "-" }}
                  </td>
                  <td>
                    <v-icon color="green lighten-1" v-if="row.status"
                      >mdi-check</v-icon
                    >
                    <v-icon color="red lighten-1" v-else>mdi-close</v-icon>
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="6">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There is no changes done by you at the moment.
                  </p>
                </td>
              </tr>
            </table>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="$emit('close', true)"
      >
        Save
      </v-btn>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close', true)"
      >
        Cancel
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { toSafeInteger, differenceWith, isEqual } from "lodash";
import CommonFunctionMixin from "@/core/lib/common/common.functions.mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonFunctionMixin],
  name: "job-ctx-confirm-schedule",
  data() {
    return {
      todayDate: moment(new Date()).format("YYYY-MM-DD"),
      scheduleDiff: [],
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Dialog,
  },
  mounted() {
    this.scheduleDiff = differenceWith(
      this.preventiveScheduleOutput,
      this.prventiveRawScheduleOutput,
      isEqual
    );
  },
  computed: {
    ...mapGetters(["preventiveScheduleOutput", "prventiveRawScheduleOutput"]),
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 70);
    },
  },
};
</script>
